import React from 'react';

import img1 from '../../../assets/images/jpg/renewable-energyBlock/framer2.jpg';
import img2 from '../../../assets/images/jpg/renewable-energyBlock/framer3.jpg';
import img3 from '../../../assets/images/jpg/renewable-energyBlock/framer1.jpg';
import mobileWireframe from '../../../assets/images/jpg/renewable-energyBlock/mobileWireframe.jpg';

import * as styles from '../styles.module.scss';

const Wireframes = () => {

  const Frames = [
    {
      id: 1,
      image: img1,
    },
    {
      id: 2,
      image: img2,
    },
    {
      id: 3,
      image: img3,
    },
  ];

  return (
    <div className={styles.wireframesContainer}>
      <div className={styles.wireframesWrapper}>
        <p>03 // 04</p>
        <h2>Wireframes</h2>
        <div className={styles.imageWrapper}>
          {Frames?.map((el) => (
            <img src={el.image} alt='Wireframes images'/>
          ))}
          <img
            className={styles.mobileWireframe}
            alt='Wireframe image'
            src={mobileWireframe}
          />
        </div>
      </div>
    </div>
  )
}

export default Wireframes
