import React from 'react';

import img1 from '../../../assets/images/png/renewable-energyBlock/receipt-search.png';
import img2 from '../../../assets/images/png/renewable-energyBlock/colorfilter.png';
import img3 from '../../../assets/images/png/renewable-energyBlock/layer.png';
import img4 from '../../../assets/images/png/renewable-energyBlock/magicpen.png';
import frame1 from '../../../assets/images/png/renewable-energyBlock/frame1.png';
import frame2 from '../../../assets/images/png/renewable-energyBlock/frame2.png';
import frame3 from '../../../assets/images/png/renewable-energyBlock/frame3.png';
import frame4 from '../../../assets/images/png/renewable-energyBlock/frame4.png';

import * as styles from '../styles.module.scss';

const TheScopeOfOurWork = () => {

  const TheScope = [
    {
      id: 1,
      title: 'Research',
      image: img1,
    },
    {
      id: 2,
      title: 'User flow',
      image: img2,
    },
    {
      id: 3,
      title: 'Wireframes',
      image: img3,
    },
    {
      id: 4,
      title: 'Design',
      image: img4,
    },
  ];

  const UserFlow = [
    {
      id: 1,
      image: frame1,
    },
    {
      id: 2,
      image: frame2,
    },
    {
      id: 3,
      image: frame3,
    },
    {
      id: 4,
      image: frame4,
    },
  ];

  return (
    <div className={styles.theScopeContainer}>
      <div className={styles.flexContainer}>
        <div className={styles.theScopeTitle}>
          <p>01 // 04</p>
          <h2>The scope of our work</h2>
        </div>
        <div className={styles.cardsContainer}>
          {TheScope?.map((el) => (
            <div className={styles.cardsWrapper}>
              <div className={styles.theScopeCards}>
                <img src={el.image} alt='The scope of our work'/>
              </div>
              <p>{el.title}</p>
            </div>
          ))}
          {TheScope?.map((el) => (
            <div className={styles.cardsMobileWrapper}>
              <img src={el.image} alt='The scope of our work'/>
              <p>{el.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.userFlow}>
        <p>02 // 04</p>
        <h2>User flow</h2>
        <div className={styles.flowContent}>
          {UserFlow?.map((el) => (
            <img src={el.image} alt='User flow buttons'/>
          ))}
        </div>
      </div>
    </div>
  )
}
export default TheScopeOfOurWork
