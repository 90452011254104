import React from 'react'

import Seo from '../../../components/Seo'

import contactsImage from '../../../assets/images/jpg/seo/Contacts-min.jpg'
import RenewableEnergyCard from "../../../components/RenewableEnergyBlock/RenewableEnergyCard";

const Index = () => {
  return (
    <>
      <Seo
        title="Justice | Renewable Energy"
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        description="Contact us via e-mail, phone, social networks or visit our comfortable office in the centre of Taganrog."
        image={contactsImage}
      />
      <div>
        <RenewableEnergyCard />
      </div>
    </>
  )
}

export default Index
