import React from 'react';

import logo from '../../../assets/images/png/renewable-energyBlock/logoRenewable.png';
import line from '../../../assets/images/png/renewable-energyBlock/greenLine.png';
import siteScreen from '../../../assets/images/jpg/renewable-energyBlock/siteScreen.jpg';
import mobileScreen from '../../../assets/images/jpg/renewable-energyBlock/mobileImage.jpg';
import mobileLine from '../../../assets/images/png/renewable-energyBlock/mobileLine.png';

import * as styles from '../styles.module.scss';

const RenewableEnergyTop = () => {
  return (
    <div className={styles.twoSidesContainer}>
      <div className={styles.leftSide}>
        <div className={styles.imageContainer}>
          <img
            src={logo}
            alt='Renewable energy logo'
            className={styles.logoImage}
          />
          <h1 className={styles.titleImage}>
            Renewable energy.
          </h1>
          <img
            src={line}
            alt='Line for the title'
            className={styles.lineImage}
          />
          <img
            src={mobileLine}
            alt='Line for the title'
            className={styles.lineMobileImage}
          />
          <p>
            redesign concept
          </p>
        </div>
      </div>
      <div className={styles.rightSide}>
        <img
          className={styles.PCImage}
          src={siteScreen}
          alt='Screenshot of the Renewable energy site'
        />
        <img
          className={styles.mobileImage}
          src={mobileScreen}
          alt='Screenshot of the Renewable energy site'
        />
      </div>
    </div>
  )
}

export default RenewableEnergyTop
