import React from 'react';

import img from '../../../assets/images/jpg/renewable-energyBlock/longImage.jpg';
import group from '../../../assets/images/png/renewable-energyBlock/groupOfImages.png';
import thanks from '../../../assets/images/jpg/renewable-energyBlock/thanks.jpg';
import longMobileImage from '../../../assets/images/jpg/renewable-energyBlock/mobileLarge.jpg';
import mobileGroup from '../../../assets/images/png/renewable-energyBlock/mobileGroup.png';

import * as styles from '../styles.module.scss';

const RenewableEnergyBottom = () => {
  return (
    <div className={styles.bottomContainer}>
      <div className={styles.bottomWrapper}>
        <img
          src={img}
          className={styles.longImage}
          alt='Largest producer of wind energy'
        />
        <img
          src={longMobileImage}
          className={styles.longMobileImage}
          alt='Largest producer of wind energy'
        />
        <div className={styles.group}>
          <h2>Other <span>pages</span></h2>
          <img
            src={group}
            className={styles.groupImages}
            alt='Other pages'
          />
          <img
            src={mobileGroup}
            className={styles.mobileGroup}
            alt='Other pages'
          />
        </div>
        <img
          className={styles.thanksImage}
          src={thanks}
          alt='Thanks for watching'
        />
      </div>
    </div>
  )
}

export default RenewableEnergyBottom
