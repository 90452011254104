import React from 'react';

import design from '../../../assets/images/jpg/renewable-energyBlock/design.jpg';
import img1 from '../../../assets/images/jpg/renewable-energyBlock/phone1.jpg';
import img2 from '../../../assets/images/jpg/renewable-energyBlock/phone2.jpg';
import img3 from '../../../assets/images/jpg/renewable-energyBlock/phone3.jpg';
import firstMobile from '../../../assets/images/jpg/renewable-energyBlock/firstMobile.jpg';
import secondMobile from '../../../assets/images/jpg/renewable-energyBlock/secondMobile.jpg';
import thirdMobile from '../../../assets/images/jpg/renewable-energyBlock/thirdMobile.jpg';
import mobileDesign from '../../../assets/images/jpg/renewable-energyBlock/mobileDesign.jpg';

import * as styles from '../styles.module.scss';

const Design = () => {

  const Phones = [
    {
      id: 1,
      image: img1,
    },
    {
      id: 2,
      image: img2,
    },
    {
      id: 3,
      image: img3,
    },
  ];

  return (
    <div className={styles.DesignContainer}>
      <div className={styles.DesignTopContainer}>
        <p>04 // 04</p>
        <h2>Design</h2>
        <img src={design} alt='Design image'/>
        <img
          src={mobileDesign}
          className={styles.mobileDesign}
          alt='Phone images mobile'
        />
      </div>
      <div className={styles.designBottomContainer}>
        <div className={styles.phoneContent}>
          {Phones?.map((el) => (
            <img src={el.image} alt='Phone images'/>
          ))}
        </div>
      </div>
      <div className={styles.designMobile}>
        <div className={styles.mobilePhones}>
          <div className={styles.mobileColumn}>
            <img src={firstMobile} alt='Phone images'/>
            <img src={secondMobile} alt='Phone images'/>
          </div>
          <div className={styles.thirdImage}>
            <img src={thirdMobile} alt='Phone images'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Design
