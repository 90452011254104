// extracted by mini-css-extract-plugin
export var renewableCard = "styles-module--renewableCard--Y6K+a";
export var pageContainer = "styles-module--pageContainer--x+OS0";
export var twoSidesContainer = "styles-module--twoSidesContainer--MpapH";
export var mobileView = "styles-module--mobileView--6iH7H";
export var leftSide = "styles-module--leftSide--gwmCV";
export var imageContainer = "styles-module--imageContainer--49HtO";
export var logoImage = "styles-module--logoImage--b7bXn";
export var titleImage = "styles-module--titleImage--bWNnX";
export var lineImage = "styles-module--lineImage--P5D5t";
export var lineMobileImage = "styles-module--lineMobileImage--SEUFP";
export var rightSide = "styles-module--rightSide--saa34";
export var PCImage = "styles-module--PCImage--NsMQb";
export var mobileImage = "styles-module--mobileImage--l96LE";
export var theScopeContainer = "styles-module--theScopeContainer--h9qTe";
export var flexContainer = "styles-module--flexContainer--MsL-5";
export var theScopeTitle = "styles-module--theScopeTitle--uNZRY";
export var cardsContainer = "styles-module--cardsContainer--ZPsQG";
export var cardsWrapper = "styles-module--cardsWrapper--mEzDi";
export var theScopeCards = "styles-module--theScopeCards--hRA5T";
export var cardsMobileWrapper = "styles-module--cardsMobileWrapper--SEyV2";
export var userFlow = "styles-module--userFlow--NGgOZ";
export var flowContent = "styles-module--flowContent--RS8FV";
export var wireframesContainer = "styles-module--wireframesContainer--HFDeH";
export var wireframesWrapper = "styles-module--wireframesWrapper--2uKng";
export var imageWrapper = "styles-module--imageWrapper--GJNTw";
export var mobileWireframe = "styles-module--mobileWireframe--ev1T+";
export var DesignContainer = "styles-module--DesignContainer--ZvB7d";
export var DesignTopContainer = "styles-module--DesignTopContainer--9XyKi";
export var mobileDesign = "styles-module--mobileDesign--Ctcik";
export var designBottomContainer = "styles-module--designBottomContainer--dHf-E";
export var phoneContent = "styles-module--phoneContent--bm7jp";
export var designMobile = "styles-module--designMobile--5UCMA";
export var mobilePhones = "styles-module--mobilePhones--MidG+";
export var mobileColumn = "styles-module--mobileColumn--WWASs";
export var thirdImage = "styles-module--thirdImage--Onsar";
export var bottomContainer = "styles-module--bottomContainer--owkfx";
export var bottomWrapper = "styles-module--bottomWrapper--qUtLL";
export var longImage = "styles-module--longImage--EdLUY";
export var longMobileImage = "styles-module--longMobileImage--Zn7FQ";
export var group = "styles-module--group--BlyY+";
export var groupImages = "styles-module--groupImages--9sZej";
export var mobileGroup = "styles-module--mobileGroup--jJJjD";
export var thanksImage = "styles-module--thanksImage--4S7wX";