import React from 'react';

import RenewableEnergyTop from './RenewableEnergyTop/RenewableEnergyTop';
import TheScopeOfOurWork from './TheScopeOfOurWork/TheScopeOfOurWork';
import Wireframes from './Wireframers/Wireframers';
import Design from './Design/Design';
import RenewableEnergyBottom from './RenewableEnergyBottom/RenewableEnergyBottom';

import * as styles from './styles.module.scss';

const RenewableEnergyCard = () => {

  return (
    <div className={styles.renewableCard}>
      <div className={styles.pageContainer}>
        <RenewableEnergyTop />
        <TheScopeOfOurWork />
        <Wireframes/>
        <Design />
        <RenewableEnergyBottom />
      </div>
    </div>
  )
}

export default RenewableEnergyCard
